import styled from 'styled-components';
import { shade } from 'polished';

export const ReposissionContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FormContainer = styled.form`
  width: 180px;
`;

export const ReposissionInput = styled.input`
  background: transparent;
  height: 2rem;
  border: 0;
  border-bottom: 2px solid;
  font-weight: bold;
  font-size: 1.125rem;
  padding: 0 0.5rem;
  width: 3.75rem;
  margin-right: 16px;

  &:focus {
    border-bottom: 10px double #ff0000;
    transition: border-bottom 0.2s;
  }
`;

export const ReposissionButton = styled.button`
  width: 6.25rem;
  border: 0;
  padding: 0.45rem;
  border-radius: 100px;
  background: #ff0000;
  color: #fff;
  font-size: 1.125rem;
  font-weight: bold;

  &:hover {
    background: ${shade(0.2, '#ff0000')};
  }
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  &:not(:disabled):hover {
    background-color: ${props => props.theme['#ff0000']};
  }
`;
