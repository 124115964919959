/* eslint-disable camelcase */
/* eslint-disable no-shadow */
import React from 'react';
import { Link } from 'react-router-dom';
import no_items_found from 'assets/no_items_found.png';
import { WarehouseItemCards } from './styles';

const NoItensFound: React.FC<Record<string, unknown>> = (): JSX.Element => {
  return (
    <>
      <WarehouseItemCards>
        <Link to="/" onClick={event => event.preventDefault()}>
          <img src={no_items_found} alt="Item Not Found!" />
          <div>
            <strong>No itens found</strong>
            <p>Please use another search term and try again</p>
          </div>
          <div className="quantity">
            <strong>No itens found</strong>
          </div>
        </Link>
      </WarehouseItemCards>
    </>
  );
};

export default NoItensFound;
