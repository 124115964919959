/* eslint-disable camelcase */
import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import verifyUserPermission from 'utils/verifyUserPermission';
import ItemPricesProps from '../../dtos/IItemPricesParamsDTO';
import {
  ItemPricesContainer,
  ItemPricesCard,
  ItemPriceObservation,
} from './styles';

/**
 * NOTA: Implementar o Skeleton usando fecthReduce, semelhante ao que foi feito em WarehouseItemList
 *
 */

const ItemPrices: React.FC<ItemPricesProps> = ({
  price_usd,
  price_brl,
  ship_chandler_price,
  item_observation,
}): JSX.Element => {
  const isLoading =
    price_usd === '' || price_brl === '' || ship_chandler_price === '';

  const hasListPricePermission = verifyUserPermission('price_list');

  return (
    <>
      {hasListPricePermission && price_usd && (
        <ItemPricesContainer>
          <ItemPricesCard>
            <header>
              <span>USD</span>
              <span> </span>
            </header>
            {isLoading ? (
              <Skeleton variant="rect" width={80} />
            ) : (
              <strong>{price_usd}</strong>
            )}
          </ItemPricesCard>

          <ItemPricesCard>
            <header>
              <span>BRL</span>
              <span>+IPI</span>
            </header>
            <strong>{price_brl}</strong>
          </ItemPricesCard>

          <ItemPricesCard>
            <header>
              <span>Revenda</span>
              <span>+IPI</span>
            </header>
            <strong>{ship_chandler_price}</strong>
          </ItemPricesCard>
        </ItemPricesContainer>
      )}
      {hasListPricePermission && item_observation && (
        <ItemPriceObservation>
          <span>{item_observation}</span>
        </ItemPriceObservation>
      )}
    </>
  );
};

export default ItemPrices;
