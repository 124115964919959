import React from 'react';
import { Switch } from 'react-router-dom';
import SignIn from 'pages/SignIn';
import ForgotPassword from 'pages/ForgotPassword';
import ResetPassword from 'pages/ResetPassword';
import Dashboard from 'pages/Dashboard';
import Warehouse from 'pages/Warehouse';
import Service from 'pages/Service';
import Profile from 'pages/Profile';
import Shelfs from 'pages/Shelfs';
import Route from './Route';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={SignIn} />
    <Route path="/forgot-password" component={ForgotPassword} />
    <Route path="/reset-password" component={ResetPassword} />
    <Route path="/dashboard" component={Dashboard} isPrivate />
    <Route path="/warehouse/:itemCode+" component={Warehouse} isPrivate />
    <Route path="/service/:itemCode+" component={Service} isPrivate />
    <Route path="/shelfs/:itemCode/:location" component={Shelfs} isPrivate />
    <Route path="/profile" component={Profile} isPrivate />
  </Switch>
);

export default Routes;
