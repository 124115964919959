import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  /* padding: 30px 0; */
`;

export const HeaderContent = styled.div`
  max-width: 920px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > img {
    height: 40px;
  }

  nav {
    display: flex;
  }

  button {
    background: transparent;
    border: 0;
    margin-left: 32px;

    svg {
      color: #000;
      width: 20px;
      height: 20px;

      /* &:hover {
        opacity: 0.8;
      } */
    &:hover {
      color: ${shade(0.2, '#15608F')};
      }
    }
  }
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 8px;
  /* margin-left: auto; */

  img {
    width: 56px;
    height: 56px;
    border-radius: 50%;
  }

  div {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    line-height: 24px;

  }

  span {
    color: #3a3a3a;
  }

  a {
    text-decoration: none;
    color: #F50157;

    &:hover {
      color: ${shade(0.2, '#F50157')};
      /* opacity: 0.8; */
    }
  }
`;
