import styled, { keyframes } from 'styled-components';
import { shade } from 'polished'; /* Usada para escurecer a cor do botão */

import signInBackground from '../../assets/sign-in-background.png';

export const Container = styled.div`
  height: 100vh; /* Container com 100% da área visível */
  display: flex;
  align-items: stretch; /* Cada item vai ficar com tamanho total da área visível, estica o máximo que puder */
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%; /* Máximo da tela  */
  max-width: 700px; /* Limita o máximo a 700px  */
`;

const appearFromLeft = keyframes`
from {
  opacity: 0;
  transform: translateX(-50px)
}
to {
  opacity: 1;
  transform: translateX(0)
}
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  animation: ${appearFromLeft} 1s;

  form {
    margin: 80px 0;
    width: 340px;
    text-align: center;

    h1 {
      margin-bottom: 24px;
    }

    a {
      color: #f4ede8;
      display: block;
      margin-top: 24px;
      text-decoration: none; /* Remover o azul de link  */
      transition: color 0.2s;

      &:hover {
        color: ${shade(0.2, '#f4ede8')};
      }
    }
  }
  > a {
    /* Esse > antes do a significa que é deste nivel para cima, isso garante não ferrar a estilização do nivel abaixo */
    color: #ff9000;
    display: block;
    margin-top: 24px;
    text-decoration: none; /* Remover o azul de link  */
    transition: color 0.2s;

    display: flex;
    align-items: center;

    svg {
      margin-right: 16px;
    }

    &:hover {
      color: ${shade(0.2, '#ff9000')};
    }
  }
`;

export const Background = styled.div`
  flex: 1; /* Vai ocupar todo o espaço menos os 700px do Content  */
  background: url(${signInBackground}) no-repeat center;
  background-size: cover; /* faz cobrir toda a área restante  */
`;
