/* eslint-disable camelcase */
import React, { createContext, useCallback, useState, useContext } from 'react';
import api from '../services/apiClient';
import SignInCredentials from '../dtos/ISignInCredentialsDTO';

interface User {
  id: string;
  name: string;
  email: string;
  avatar_url: string;
}

interface AuthState {
  token: string;
  refresh_token: string;
  user: User;
}

interface AuthContextData {
  user: User;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  updateUser(user: User): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@warehouse_explorer:token');
    const refresh_token = localStorage.getItem(
      '@warehouse_explorer:refresh_token',
    );
    const user = localStorage.getItem('@warehouse_explorer:user');

    if (token && user && refresh_token) {
      api.defaults.headers.authorization = `Bearer ${refresh_token}`;
      return { token, user: JSON.parse(user), refresh_token };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ email, password }) => {
    const response = await api.post('sessions', {
      email,
      password,
    });

    const { token, user, refresh_token } = response.data;

    localStorage.setItem('@warehouse_explorer:token', token);
    localStorage.setItem('@warehouse_explorer:user', JSON.stringify(user));
    localStorage.setItem('@warehouse_explorer:refresh_token', refresh_token);

    api.defaults.headers.authorization = `Bearer ${refresh_token}`;

    setData({ token, user, refresh_token });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@warehouse_explorer:lastRole');
    localStorage.removeItem('@warehouse_explorer:token');
    localStorage.removeItem('@warehouse_explorer:user');
    localStorage.removeItem('@warehouse_explorer:refresh_token');

    setData({} as AuthState);
  }, []);

  const updateUser = useCallback(
    (user: User) => {
      localStorage.setItem('@warehouse_explorer:user', JSON.stringify(user));

      setData({
        token: data.token,
        refresh_token: data.refresh_token,
        user,
      });
    },
    [setData, data.token, data.refresh_token],
  );

  return (
    <AuthContext.Provider
      value={{ user: data.user, signIn, signOut, updateUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
