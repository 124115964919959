import styled from 'styled-components';

export const Container = styled.div`
  position: relative; /*  Será relativa ao container e não ao resto da página. */

  span {
    width: 160px; /** Largura */
    background: #ff9000;
    padding: 8px; /** Será 8px de todos os lados */
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    opacity: 0;
    transition: opacity 0.4s;
    visibility: hidden; /* Evita do mouse passar por cima do span e ele reaparecer*/

    position: absolute;
    bottom: calc(
      100% + 12px
    ); /** O uso da função calc permite alem de um valor adicionar outro */

    left: 50%;
    transform: translateX(-50%);
    /** Hack para deixar na metade  */

    color: #312e38;

    &::before {
      /* Esse código faz uma flexinha no Tootip */
      content: '';
      border-style: solid;
      border-color: #ff9000 transparent;
      border-width: 6px 6px 0 6px;
      /* bottom: 20px; */
      top: 100%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &:hover span {
    opacity: 1;
    visibility: visible;
  }
`;
