interface userData {
  name: string;
  roles: string[];
  permissions: permissionsData[];
}

interface permissionsData {
  id: string;
  name: string;
  description: string;
}

function recoverUserData(): userData | undefined {
  const userData = localStorage.getItem('@warehouse_explorer:user');
  if (!userData) {
    return undefined;
  }
  const user = JSON.parse(userData);
  return user;
}

export default function verifyUserPermission(
  permissionName: string,
): permissionsData | undefined {
  const user = recoverUserData();
  const permissionExists = user?.permissions.find(
    permission => permission.name === permissionName,
  );
  return permissionExists;
}
